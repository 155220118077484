import React, { Component, useState } from "react"

export const AppContext = React.createContext({})

export const AppContextProvider = (props) => {
    const [isHeader, setIsHeader] = useState(false);
    const handleHeaderToggle = (value) => {
        setIsHeader(value)
    }

    return (
        <AppContext.Provider
            value={{ isHeader, handleHeaderToggle: handleHeaderToggle }}
        >
            {props.children}
        </AppContext.Provider>
    )
}



