/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import { AppContextProvider } from "./src/context/app";
import "./src/styles/_app.scss"

export const wrapRootElement = ({ element }) => (
  <AppContextProvider>{element}</AppContextProvider>
)